import React, {useState} from "react";
import {Alert, Button, Col, Input, Row, Spin} from "antd";
import {sendRequest} from "../api/api";

import 'antd/lib/grid/style/index.css'
import 'antd/lib/typography/style/index.css'

import 'antd/lib/alert/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/modal/style/index.css'
import 'antd/lib/spin/style/index.css'

export default function Call() {
    const [name, setName] = useState<string>();
    const [phone, setPhone] = useState<string>();
    const [note, setNote] = useState<string>();

    const [sending, setSending] = useState<boolean>(false);
    const [sentError, setSentError] = useState<String>();
    const [sent, setSent] = useState<boolean>(false);

    const scheduleDemo = () => {
        if (!name) {
            setSentError("Пожалуйста, введите ваше имя")
        } else if (!phone) {
            setSentError("Пожалуйста, введите номер телефона")
        } else {
            setSending(true)
            setSentError(undefined)
            sendRequest(name, phone, note).then(r => {
                if (r.ok) {
                    // @ts-ignore
                    setSent(true)
                    setSending(false)
                } else {
                    setSentError("Отправка заявок в данный момент недоступна. Пожалуйста, позвоните по номеру телефона или напишите в WhatsApp.")
                    setSending(false)
                }
            }).catch(r => {
                setSentError("Отправка заявок в данный момент недоступна. Пожалуйста, позвоните по номеру телефона или напишите в WhatsApp.")
                setSending(false)
            })
        }
    }

    return (
        <Col>
            <Row justify='center'>
                <h2 className={"ant-typography"} style={{textAlign: 'center', marginTop: "2rem", marginBottom: "2rem"}}>Оставить свой номер</h2>

                <Input
                    value={name}
                    type="name"
                    size="large"
                    placeholder="Имя"
                    onChange={(e) => {
                        setName(e.currentTarget.value)
                    }}
                    style={{marginBottom: 10}}>
                </Input>
                <Input
                    value={phone}
                    type="tel"
                    size="large"
                    placeholder="Телефон (+70001112233)"
                    onChange={(e) => {
                        setPhone(e.currentTarget.value)
                    }}>
                </Input>

                <Input.TextArea style={{marginTop: 10, marginBottom: 10}}
                                size={"large"} rows={4}
                                placeholder="Вы можете описать ваш запрос, а также задать любые дополнительные вопросы здесь, но это необязательно" maxLength={1000}
                                onChange={(e) => {
                                    setNote(e.currentTarget.value)
                                }}
                />

                <span className="ant-typography ant-typography-secondary"
                      style={{textAlign: "center"}}>Я&nbsp;перезвоню вам по&nbsp;указанному номеру телефона в&nbsp;рабочее время</span>
            </Row>
            <Row justify='center'>
                {sentError ? <Alert style={{marginTop: "1rem"}} message={sentError} type="warning"/> : <></>}
            </Row>
            <Row justify='center'>
                {sent
                    ? <Alert style={{marginTop: "1rem"}}
                             message="Спасибо, я приняла вашу заявку. Свяжусь с вами в рабочие часы."
                             type="success"/>
                    : sending
                        ? <Spin style={{marginTop: 20}} size={"large"}/>
                        : <Button size="large" shape="round" type="primary" block={true} onClick={() => {
                            scheduleDemo()
                        }} style={{marginTop: 20}}>
                            <h5 className={"ant-typography"} style={{margin: 0, color: "white"}}>Оставить заявку на звонок</h5>
                </Button>
                }
            </Row>
        </Col>
    )
}