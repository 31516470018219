import React from "react";
import Articles from "../article/Articles";
import {MainHeader} from "./Landing";

function Blog() {
    return (
        <div className="App" id="top">
            <section className="ant-layout" style={{height: "100vh"}}>
                <MainHeader/>
                <main className="ant-layout-content" style={{marginTop: "64px"}}>
                    <Articles/>

                    <footer className="ant-layout-footer" style={{textAlign: "center", marginTop: "1rem"}}>
                        <div className="ant-typography" style={{marginBottom: 0}}>© 2023 Аксельрод Наталья Борисовна
                        </div>
                        <div className="ant-typography" style={{marginBottom: 0}}>Разработка: <a className={"my-color"}
                                                                                                 href={"https://axelrod.co"}>axelrod.co</a>
                        </div>
                    </footer>
                </main>
            </section>
        </div>
    );
}

export default Blog;
