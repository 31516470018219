import React from "react";
import {Badge, Button, Col, Row} from "antd";
import { PhoneOutlined, WhatsAppOutlined, EnvironmentOutlined } from '@ant-design/icons';
import {MainHeader} from "./Landing";
import Call from "../call/Call";

function SignUp() {
    return (
        <div className="App" id="top">
            <section className="ant-layout" style={{height: "100vh"}}>
                <MainHeader/>
                <main className="ant-layout-content" style={{marginTop: "64px"}}>
                    <Row justify="center" align="middle" style={{textAlign: "center"}}>
                        <Col
                            xs={{span: 24}}
                            sm={{span: 24}}
                            md={{span: 22}}
                            lg={{span: 18}}
                            xl={{span: 18}}
                            xxl={{span: 16}}
                            style={{padding: "1rem"}}
                        >
                            <h2 className={"ant-typography"} style={{marginBottom: "2rem", marginTop: "2rem"}}>Запись на консультацию</h2>

                            {/*<h4 className={"ant-typography small"} style={{textAlign: 'center', marginTop: "2rem", marginBottom: "2rem"}}>Вы можете записаться по телефону, через WhatsApp или оставить свой номер для звонка.</h4>*/}

                            <Row gutter={24} style={{marginBottom: "3rem", justifyContent: "center"}}>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={11}
                                    lg={11}
                                    xl={8}
                                    xxl={8}
                                    style={{marginBottom: "1rem"}}
                                >
                                    <div className="ant-card ant-card-bordered" style={{borderRadius: ".5rem"}}>
                                        <div className="ant-card-body">
                                            <div className="ant-card-body">
                                                <h4 style={{marginTop: 0, marginBottom: "0rem"}}
                                                    className={"ant-typography"}>Очная консультация</h4>
                                                <p style={{marginTop: 0, marginBottom: "1rem"}}
                                                   className={"small ant-typography"}>2 часа</p>
                                                <h4 style={{marginTop: 0, marginBottom: "0rem"}}
                                                    className={"ant-typography"}>20 000 ₸</h4>
                                            </div>
                                            <Button
                                                href="#contacts" shape={"round"} size={"large"} type={"default"} block={true}>
                                                Записаться
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={11}
                                    lg={11}
                                    xl={8}
                                    xxl={8}
                                    style={{marginBottom: "1rem"}}
                                >
                                    <div className="ant-card ant-card-bordered" style={{borderRadius: ".5rem"}}>
                                        <div className="ant-card-body">
                                            <div className="ant-card-body">
                                                <h4 style={{marginTop: 0, marginBottom: "0rem"}}
                                                    className={"ant-typography"}>Семейная консультация</h4>
                                                <p style={{marginTop: 0, marginBottom: "1rem"}}
                                                   className={"small ant-typography"}>2 часа</p>
                                                <h4 style={{marginTop: 0, marginBottom: "0rem"}}
                                                    className={"ant-typography"}>25 000 ₸</h4>
                                            </div>
                                            <Button
                                                href="#contacts" shape={"round"} size={"large"} type={"default"} block={true}>
                                                Записаться
                                            </Button>
                                        </div>
                                    </div>
                                </Col>                                
                            </Row>

                            <h2 className={"ant-typography"} style={{textAlign: 'center', marginTop: "2rem", marginBottom: "2rem"}}>Контакты</h2>

                            <Button
                                icon={<PhoneOutlined />}
                                href="tel:+77015981068" shape={"round"} size={"large"} type={"primary"} block={true}>
                                Позвонить (+7 701 598-10-68)
                            </Button>

                            <Button
                                icon={<WhatsAppOutlined />}
                                href="https://wa.me/77015981068" shape={"round"} size={"large"}
                                    type={"primary"} block={true} style={{marginTop: "1rem", marginBottom: "1rem"}}>
                                Написать в WhatsApp
                            </Button>

                            <Call/>

                            <h2 className={"ant-typography"} style={{textAlign: 'center', marginTop: "3rem", marginBottom: "2rem"}}>Адрес</h2>

                            <h4 className={"ant-typography small"} style={{textAlign: 'center', marginTop: "2rem", marginBottom: "2rem"}}><EnvironmentOutlined /> Алматы, ул. Толе-Би, д. 282</h4>

                            <Button
                                icon={<EnvironmentOutlined />}
                                href="https://goo.gl/maps/togBDx9az8VspwNZ9" shape={"round"} size={"large"} type={"primary"} block={true}>
                                Google Maps
                            </Button>

                            <Button
                                icon={<EnvironmentOutlined />}
                                href="https://yandex.com.ge/maps/-/CCUZFGG~WD" shape={"round"} size={"large"} type={"primary"} block={true}
                                style={{marginTop: "1rem"}}>
                                Яндекс.Карты
                            </Button>

                            <Button
                                icon={<EnvironmentOutlined />}
                                href="https://go.2gis.com/mbmuz" shape={"round"} size={"large"}
                                type={"primary"} block={true} style={{marginTop: "1rem", marginBottom: "2rem"}}>
                                2ГИС
                            </Button>

                            <Row justify={"center"}>
                                <Col
                                    xs={{span: 24}}
                                    sm={{span: 24}}
                                    md={{span: 22}}
                                    lg={{span: 18}}
                                    xl={{span: 16}}
                                    xxl={{span: 14}}
                                >
                                    <img style={{borderRadius: ".5rem", height: "auto", width: "100%", marginBottom: "1rem"}}
                                         className="my-shadow"
                                         height={1500} width={2000}
                                         alt={"Приём"}
                                         src={"../images/background.webp"}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <footer className="ant-layout-footer" style={{textAlign: "center", marginTop: "1rem"}}>
                        <div className="ant-typography" style={{marginBottom: 0}}>© 2023 Аксельрод Наталья Борисовна
                        </div>
                        <div className="ant-typography" style={{marginBottom: 0}}>Разработка: <a className={"my-color"}
                                                                                                 href={"https://axelrod.co"}>axelrod.co</a>
                        </div>
                    </footer>
                </main>
            </section>
        </div>
    );
}

export default SignUp;
