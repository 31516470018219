import React from "react";

import './App.css'
import 'antd/lib/avatar/style/index.css'
import 'antd/lib/button/style/index.css'
import 'antd/lib/card/style/index.css'
import 'antd/lib/collapse/style/index.css'
import 'antd/lib/comment/style/index.css'
import 'antd/lib/layout/style/index.css'
import 'antd/lib/menu/style/index.css'
import 'antd/lib/space/style/index.css'
import 'antd/lib/typography/style/index.css'
import 'antd/lib/grid/style/index.css'
import 'antd/lib/badge/style/index.css'
import 'antd/lib/style/index.css'

import Landing from "./page/Landing";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import SignUp from "./page/SignUp";
import Blog from "./page/Blog";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="blog" element={<Blog/>}>
                    {/*<Route path="psychology" element={<Blog/>}/>*/}
                    {/*<Route path="processes-in-psychology" element={<Blog/>}/>*/}
                    {/*<Route path="psychologist-can-help" element={<Blog/>}/>*/}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
