import React, {Suspense, useState} from "react";
import {Button, Spin} from "antd";

import 'antd/lib/button/style/index.css'
import 'antd/lib/typography/style/index.css'

const LeaveReviewModal = React.lazy(() => import('./leaveReviewModal'));

export function LeaveReview() {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <Button
                block={true}
                type="default"
                size="large"
                shape="round"
                onClick={() => {
                    setShowModal(!showModal)
                }}
            >
                <h5 className={"ant-typography"} style={{margin: 0}}>Оставить отзыв</h5>
            </Button>
            <Suspense fallback={<Spin size="large"/>}>
                <LeaveReviewModal visible={showModal} onCancel={() => {
                    setShowModal(false)
                }}></LeaveReviewModal>
            </Suspense>
        </>
    )
}