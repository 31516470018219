import {Col, Collapse, Row} from "antd";
import React from "react";
import SignUpNow from "../component/SignUpNow";

const {Panel} = Collapse;

const TextBasics = <div style={{textAlign: "left"}}>
    <h4 className={"ant-typography"}>
        Основные функции психологии. Процессы в&nbsp;психологии.
    </h4>

    <p className={"ant-typography small"}>
        Одной из&nbsp;важнейших функций является изучение особенностей <b>сознания</b> людей, формирование общих
        принципов и&nbsp;закономерностей, по&nbsp;которым действует индивид.
    </p>
    <p className={"ant-typography small"}>
        Наука выявляет <b>скрытые возможности</b> психики человека, причины и&nbsp;факторы, влияющие на&nbsp;поведение
        людей.
    </p>
    <p className={"ant-typography small"}>
        Практическое её&nbsp;применение заключается в&nbsp;помощи человеку, выработке рекомендаций и&nbsp;стратегии
        действий в&nbsp;различных ситуациях; во&nbsp;всех сферах, где людям приходится взаимодействовать друг
        с&nbsp;другом роль психологии неоценима.
        Она позволяет человеку правильно выстроить отношения с&nbsp;окружающими, избежать конфликтов, научиться уважать
        интересы других людей и&nbsp;считаться с&nbsp;ними.
        Психология помогает нам глубже разобраться в&nbsp;своих проблемах и&nbsp;в&nbsp;их&nbsp;причинах, осознать свои
        недостатки и&nbsp;сильные стороны.
    </p>
    <p className={"ant-typography small"}>
        Психика человека представляет собой единое целое. Все процессы, происходящие в&nbsp;ней, тесно взаимосвязаны.
    </p>
    <p className={"ant-typography small"}>
        Принято выделять следующие процессы в&nbsp;психологии
        человека: <b>познавательные</b>, <b>эмоциональные</b> и&nbsp;<b>волевые</b>.
    </p>
    <p className={"ant-typography small"}>
        Первые из&nbsp;них включают
        в&nbsp;себя <b>память</b>, <b>мышление</b>, <b>восприятие</b>, <b>внимание</b> и&nbsp;<b>ощущения</b>.
        Их&nbsp;главная особенность состоит в&nbsp;том, что именно благодаря&nbsp;им, человек реагирует и&nbsp;отвечает
        на&nbsp;воздействия со&nbsp;стороны внешнего мира.
    </p>
    <p className={"ant-typography small"}>
        <b>Настроение</b>, <b>чувства</b> и <b>эмоции</b> формируют отношение человека к&nbsp;тем или иным событиям,
        позволяют оценивать себя и&nbsp;окружающих.
    </p>
    <p className={"ant-typography small"}>
        Волевые психические процессы представлены непосредственно <b>волей</b> и&nbsp;<b>мотивацией</b>,
        а&nbsp;также <b>проактивностью</b>. Они позволяют человеку контролировать свои действия и&nbsp;поступки,
        управлять поведением и&nbsp;эмоциями. Кроме того, волевые процессы психики отвечают за&nbsp;способность
        добиваться поставленных целей, достигать желаемых высот в&nbsp;тех или иных сферах.
    </p>

</div>

const TextWhatIs = <div style={{textAlign: "left"}}>
    <h4 className={"ant-typography"}>
        Понятия психологии. Объект и&nbsp;предмет психологии.
    </h4>

    <p className={"ant-typography small"}>
        <b>Психология</b> (с&nbsp;греч. <i>psyche</i>&nbsp;&mdash; душа, <i>logos</i>&nbsp;&mdash; учение)&nbsp;&mdash; наука,
        изучающая закономерности развития, механизмы функционирования психики и&nbsp;психической деятельности человека.
    </p>

    <p className={"ant-typography small"}>
        <b>Объект</b> психологии&nbsp;&mdash; деятельность людей, их&nbsp;взаимодействие, друг с&nbsp;другом, поведение
        в&nbsp;различных ситуациях.
    </p>

    <p className={"ant-typography small"}>
        <b>Предмет</b> психологии&nbsp;&mdash; это психические процессы, состояние и&nbsp;свойства личности, это
        механизмы психической деятельности, психологические факты и&nbsp;&laquo;законы&raquo;.
    </p>
</div>

const TextCanHelp = <div style={{textAlign: "left"}}>
    <h4 className={"ant-typography"}>Психолог может помочь.</h4>

    <p className={"ant-typography small"}>
        Реакция психики человека на&nbsp;любое событие достаточно закономерна. Результат той или иной поведенческой
        реакции можно прогнозировать. Эти закономерности изучает наука психология.
    </p>

    <p className={"ant-typography small"}>
        А&nbsp;вы&nbsp;знаете эти законы?
        Закономерности функционирования нашей психики? Проще говоря, как это работает?
        Вы&nbsp;можете попытаться самостоятельно разобраться в&nbsp;проблеме. Но&nbsp;профессиональную помощь вам окажет
        психолог.
    </p>

    <p className={"ant-typography small"}>Мы&nbsp;все очень разные!
        И&nbsp;каждый стремится быть счастливым! Желает обрести душевное равновесие, испытывать радость, получать
        удовольствие, чувствовать силы!
        Но&nbsp;жизнь наша не&nbsp;безоблачна, полна стрессов!
        Психологические проблемы&nbsp;&mdash; реалии жизни.
    </p>

    <p className={"ant-typography small"}>А&nbsp;давайте подумаем, и&nbsp;ответим себе на&nbsp;вопрос, на&nbsp;сколько
        полно удовлетворены
        ваши мечты и&nbsp;желания?
        Возможно, какая-то потребность не&nbsp;реализована; что-то очень желаемое, взлелеянное, по&nbsp;причине нехватки
        времени, средств, сложившихся обстоятельств&nbsp;&mdash; <b>упущено</b>, и&nbsp;закопано глубоко
        в&nbsp;подсознание? Вы&nbsp;получаете удовольствие от&nbsp;работы? А&nbsp;домой возвращаетесь с&nbsp;улыбкой?
        Устраивает&nbsp;ли вас материальная сторона, обеспечивающая качество жизни? Выгорание не&nbsp;испытывали?
    </p>

    <p className={"ant-typography small"}>
        Не&nbsp;беспокоит ощущение&nbsp;&mdash; будто вы&nbsp;вообще, проживаете не&nbsp;свою жизнь,
        не&nbsp;по&nbsp;собственному сценарию?! И&nbsp;жгучее желание&nbsp;&mdash; сбросить маски, поменять <b>роли</b>?!..
    </p>

    <p className={"ant-typography small"}>
        Вас тревожит состояние вашего здоровья? Вы&nbsp;бываете раздражительны, нервозны? Вам знакомы страх, тревога,
        чувство вины?
    </p>

    <p className={"ant-typography small"}>
        Как складываются отношения в&nbsp;семье: ваша &laquo;вторая половина&raquo; вас любит, понимает, ценит, уважает,
        поддерживает? Или наоборот? И&nbsp;имеется&nbsp;ли она в&nbsp;наличии? Вы&nbsp;знаете, что такое душевное
        одиночество?
    </p>

    <p className={"ant-typography small"}>
        А&nbsp;может, (бывает и&nbsp;так), вы&nbsp;запутались в&nbsp;любовном треугольнике и&nbsp;не&nbsp;видите выхода?
    </p>

    <p className={"ant-typography small"}>Как вы&nbsp;переносите предательство, измену, ревность, ложь?
        Вас не&nbsp;мучает бессонница?
        Насколько тяжело вы&nbsp;переносите потери?
        Есть в&nbsp;вашем кругу люди, на&nbsp;которых можно положиться, способные выслушать, подать руку
        помощи&nbsp;&mdash; настоящие друзья? А&nbsp;если нет&nbsp;&mdash; то&nbsp;почему? Как обстоят дела
        с&nbsp;коммуникацией? Как вы&nbsp;реагируете на&nbsp;капризы детей; брюзжание, наставления родителей?
    </p>

    <p className={"ant-typography small"}>
        Какие чувства и&nbsp;эмоции возникают, когда память возвращает вас, возможно, в&nbsp;безрадостное детство, где
        обижали и&nbsp;наказывали, где было много вопросов&nbsp;&mdash; и&nbsp;не&nbsp;было ответов, время без ласки,
        любви и&nbsp;поддержки,&nbsp;&mdash; и, как следствие,&nbsp;&mdash; порой тяжелый отпечаток на&nbsp;взрослую
        жизнь!..
    </p>

    <p className={"ant-typography small"}>
        Вам бывает плохо? Просто плохо?! Бывает? Знакомо ощущение, что &laquo;все&raquo; достало? И&nbsp;хочется все
        бросить и&nbsp;бежать...
    </p>

    <h4 className={"ant-typography"}>
        Откликается?
    </h4>

    <p className={"ant-typography small"}>
        Чтобы ответить на&nbsp;вопросы: &laquo;что?&raquo;, &laquo;как?&raquo;, &laquo;зачем?&raquo;; разобраться
        в&nbsp;себе, в&nbsp;своем жизненном сценарии, навести &laquo;порядок в&nbsp;голове&raquo;, расставить приоритеты
        и&nbsp;личные границы, необходимо выяснить, чем вызваны и&nbsp;как справляться со&nbsp;своими психологическими
        проблемами, знать инструменты психологической защиты и&nbsp;реабилитации.
    </p>

    <p className={"ant-typography small"}>
        Знание природы наших поступков&nbsp;&mdash; важно и&nbsp;полезно!
    </p>

    <p className={"ant-typography small"}>
        Интересно разобраться, понять: почему мы&nbsp;ведем себя <b>так</b>, а&nbsp;не&nbsp;как-либо иначе? Поступаем
        по-разному в&nbsp;идентичных жизненных ситуациях. Почему из&nbsp;множества вариантов достижения цели или форм
        реагирования на&nbsp;внешние и&nbsp;внутренние воздействия, мы&nbsp;выбираем именно <b>те</b>, которые
        выбираем?! Человек создает в&nbsp;своем внутреннем психологическом пространстве <b>модель</b> внешнего,
        объективно существующего мира, а&nbsp;так&nbsp;же воссоздает прошедшее и&nbsp;прогнозирует будущее.
    </p>

    <p className={"ant-typography small"}>
        Человек&nbsp;&mdash; разумен! Способен управлять собственным поведением, (сообразуясь с&nbsp;вышеуказанной
        моделью), и&nbsp;активно преобразовать предметный мир с&nbsp;целью создания наилучших условий для адаптации.
    </p>
</div>

export default function Articles() {
    return (
        <>
            <Row justify="center" align="middle" style={{textAlign: "center"}}>
                <Col
                    xs={{span: 24}}
                    sm={{span: 24}}
                    md={{span: 22}}
                    lg={{span: 18}}
                    xl={{span: 14}}
                    xxl={{span: 12}}
                    style={{padding: "1rem"}}
                >
                    <h2 className={"ant-typography"} style={{marginBottom: "2rem", marginTop: "2rem"}}>Статьи</h2>

                    <Row>
                        <div className={"ant-typography"}
                             style={{textAlign: "center", width: "100%", color: "gray"}}>Нажмите на заголовок для
                            просмотра статьи
                        </div>
                    </Row>

                    <Collapse
                        style={{backgroundColor: "#FAFAFA", border: 0, borderRadius: ".5rem", marginBottom: "1rem"}}>
                        <Panel
                            header={<h4 className={"ant-typography"} style={{marginBottom: 0}}>Понятия психологии</h4>}
                            key="1" style={{
                            backgroundColor: "#FAFAFA",
                            border: 0,
                            borderTop: 0,
                            borderRadius: ".5rem"
                        }}>
                            {TextWhatIs}
                        </Panel>
                        <Panel header={<h4 className={"ant-typography"} style={{marginBottom: 0}}>Основные функции и
                            процессы в
                            психологии</h4>} key="2" style={{
                            backgroundColor: "#FAFAFA",
                            border: 0,
                            borderTop: 0,
                            borderRadius: ".5rem"
                        }}>
                            {TextBasics}
                        </Panel>
                        <Panel header={<h4 className={"ant-typography"} style={{marginBottom: 0}}>Психолог может
                            помочь</h4>}
                               key="3" style={{
                            backgroundColor: "#FAFAFA",
                            border: 0,
                            borderTop: 0,
                            borderRadius: ".5rem"
                        }}>
                            {TextCanHelp}
                        </Panel>
                    </Collapse>

                    <SignUpNow/>
                </Col>
            </Row>
        </>
    )
}