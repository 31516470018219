import {Col, Row} from "antd";
import {LeaveReview} from "./leaveReview";
import React from "react";
import SignUpNow from "../component/SignUpNow";

export default function Review() {
    return (
        <>
            <Row justify="center" align="middle" style={{textAlign: "center", marginTop: "1rem"}}>
                <Col
                    xs={{span: 24, order: 0}}
                    sm={{span: 24, order: 0}}
                    md={{span: 12, order: 1}}
                    lg={{span: 12, order: 1}}
                    xl={{span: 10, order: 1}}
                    xxl={{span: 10, order: 1}}
                    style={{padding: "1rem"}}
                >
                    <h2 className={"ant-typography"} style={{marginBottom: "2rem"}}>Отзывы о моей работе</h2>
                    <LeaveReview/>
                </Col>
            </Row>

            <Row justify="center">
                <Col
                    xs={{span: 24}}
                    sm={{span: 24}}
                    md={{span: 22}}
                    lg={{span: 18}}
                    xl={{span: 18}}
                    xxl={{span: 16}}
                    style={{padding: "1rem"}}
                >
                    <Row gutter={24}>
                        <ReviewCard name={"Амангелды"} content={
                            "Доброе утро Наталья. После вчерашнего посещения я сделал выводы. Что наши желания исполняются нашими действиями. "
                            + "То что было внутри меня, вы вытащили их в наружу и  дали мне в руки. Дальше я сам. И понял ещё одно, "
                            + "что к любви нужно прикладывать действие. Спасибо вам. Жизнь это бесценный дар и она не состоит из одних только удовольствии. "
                            + "Ведь не будет тьмы не познаешь света. Не познаешь одно не научишься оценивать другое. Всё дуально в этом мире. Признаюсь, вчера тяжело пришлось когда расписывали диаграмму. "
                            + "Просто не был подготовлен к этому, впал в какой-то ступор. Но я понимаю это часть вашей работы. Спасибо вам. Успехов вам в вашей работе. Хорошего дня. 😊"
                        }/>

                        <ReviewCard name={"Наталья"} content={
                            "Наталья Борисовна здравствуйте. Благодарю за вчерашний сеанс, это было мощно."
                        }/>

                        <ReviewCard name={"Геннадий"} content={
                            "Хотел бы сказать вам спасибо за прошлую консультацию. Появилась уверенность, что это не наши бредни, а действительно ысе получится может. Вы супер, хотя моментами я волновался))"
                        }/>

                        <ReviewCard name={"Таис"} content={
                            "Благодарю, мы вас часто вспоминаем и ООООЧЕНЬ  вам благодны"
                        }/>

                        <ReviewCard name={"Асема"} content={
                            "Наталья мне на душе стало легче и спокойно,вы такая нежная очень приятная , благодарю вас,\n" +
                            "\nПосле вашего приема, после первого даже,у меня появилась стержень сильное,будто защита от того чела  который не существует для меня"
                        }/>

                        <ReviewCard name={"Гаухар"} content={
                            "Добрый день, у меня все потихоньку налаживается)\n" +
                            "вчера после сеанса чувствовала прилив энергии, ходила более уверенной в социуме👌🏻✅"
                        }/>

                        <ReviewCard name={"Геннадий"} content={
                            "3 техники которые вы мне дали отлично работают пробую, делаю. Помогают быть спокойным. Благодарю вас"
                        }/>

                        <ReviewCard name={"Иса"} content={
                            "Спасибо вам вы специалист с БОЛЬШОЙ БУКВЫ"
                        }/>

                        <ReviewCard name={"Эдуард"} content={
                            "Конечно, нет ничего с чем недьзя справиться, главное найти подход;)\n" +
                            "Спасибо вам еще раз\n" +
                            "Очень вам благодарен"
                        }/>

                        <ReviewCard name={"Наталья"} content={
                            "🤝🏻\n" +
                            "Вы мой самый любимый психолог😘😘😘\n" +
                            "Спасибо большое вам🌺🌸🌷\n" +
                            "Безумно счастлива нашему знакомству🔥\n" +
                            "Вы 🔥🔥🔥🔥\n" +
                            "Спасибо большое)"
                        }/>
                    </Row>

                    <SignUpNow/>
                </Col>
            </Row>


        </>
    )
}


function ReviewCard({name, content}) {
    return <>
        <Col
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={8}
            xxl={8}
            style={{marginBottom: "1rem"}}
        >
            <div className="ant-card ant-card-bordered" style={{borderRadius: ".5rem"}}>
                <div className="ant-card-body">
                    <div className="ant-comment" style={{textAlign: "left"}}>
                        <div className="ant-comment-inner">
                            <div className="ant-comment-avatar">
                                <span className="ant-avatar ant-avatar-circle" style={{backgroundColor: "#0d6efd"}}>
                                    <span className="ant-avatar-string">{name[0]}</span>
                                </span>
                            </div>
                            <div className="ant-comment-content">
                                <div className="ant-comment-content-author">
                                    <span className="ant-comment-content-author-name">
                                        <p className="ant-typography small">{name}</p>
                                    </span>
                                </div>
                                <div className="ant-comment-content-detail">
                                    <p className="ant-typography small">
                                        {content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    </>
}