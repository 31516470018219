import React from "react";
import {Button, Col, Row} from "antd";

function SignUpNow() {
    return (
        <Row justify={"center"}>
            <Col
                xs={24}
                sm={24}
                md={20}
                lg={18}
                xl={14}
                xxl={12}>
                <Button style={{marginTop: "1rem"}}
                        block={true}
                        href="/signup" shape={"round"} size={"large"} type={"primary"}>
                    Записаться на консультацию
                </Button>
            </Col>
        </Row>
    )
}

export default SignUpNow;
